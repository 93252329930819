<template>
  <div class="home" v-scroll="handleScroll">
    <intro @click-scroll-down="scrollTo('about')"/>
    <section id="shop" class="section--padding">
      <category-list :items="categories"/>
    </section>
    <div v-if="banner && banner.length" class="swiper-banner">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in banner" :key="index">
          <a v-if="item.LINK" :href="item.LINK" target="_blank">
            <v-img :src="item.PREVIEW_PICTURE"/></a>
          <v-img v-else :src="item.PREVIEW_PICTURE"/>
        </div>
      </div>
    </div>
    <collections id="flavours" :collections="collections" :flavours="flavours"/>
    <history id="story"/>
    <history2/>
    <laurent/>
    <corporate-orders id="corporate"/>
    <contacts id="contacts"/>
  </div>
</template>

<script>
import Intro from './Home/intro';
import History from './Home/history';
import History2 from './Home/history2';
import Laurent from './Home/laurent';
import Collections from '@/components/collections';
import CategoryList from '@/components/category-list';
import CorporateOrders from './Home/corporate-orders';
import Contacts from './Home/contacts';
import {scrollTo} from '@/utils/utils';
import {Navigation, Swiper, Autoplay} from "swiper";
import 'swiper/swiper.min.css';

let swiper;

export default {
  name: "Home",

  components: {
    Intro,
    History,
    History2,
    Laurent,
    Collections,
    CategoryList,
    CorporateOrders,
    Contacts,
  },
  computed: {
    categories() {
      return this.$store.state.page.HomeController.categories;
    },
    collections() {
      return this.$store.state.page.HomeController.collections;
    },
    flavours() {
      return this.$store.state.page.HomeController.flavours;
    },
    banner() {
      return this.$store?.state?.common?.banner;
    }
  },
  methods: {
    handleScroll() {
      // set anchor
      let anchor = null;
      const ids = ["shop", "flavours", "story", "corporate", "stores", "partnership", "contacts"];
      ids.forEach(id => {
        const el = document.getElementById(id);
        if (!el) return;
        const rect = el.getBoundingClientRect();
        if (rect.top <= 0) anchor = id;
      });
      const path = window.location.href.replace(/^(.*?)(#.+)/, "$1");
      let currentAnchor = window.location.href.replace(/^.*?#(.+)/, "$1");
      if (currentAnchor === path) currentAnchor = null;
      if (anchor && anchor !== currentAnchor) {
        //console.log("New anchor: "+anchor);
        window.history.pushState("", "", path + "#" + anchor);
        //this.$router.replace({path: "/#"+anchor, query:{forced:true}});
        this.$store.state.selectedMenuItemId = anchor;
      } else if (!anchor && currentAnchor) {
        //console.log("New anchor: unset");
        //this.$router.replace({path: "/", query:{forced:true}});
        window.history.pushState("", "", path);
        this.$store.state.selectedMenuItemId = null;
      }
    },
    scrollTo(id) {
      scrollTo(id);
    },
  },
  mounted() {
    swiper = new Swiper('.swiper-banner', {
      freeMode: true,
      modules: [Autoplay],
      slidesPerView: 1,
      spaceBetween: 0,
      autoplay: true
    });

  },

}
</script>

<style lang="scss">
.home {
  .collections {
    background: $gray-lightest;
  }

  #shop {
    padding-bottom: 0;
    @include up($md) {
      padding-bottom: 32px;
    }
  }
}
</style>